.layout {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5;
} 

.appContentCard {
  background-color: transparent;
  padding: 0px 20px 20px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.components {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.anticon-close-circle {
  color: teal;
}
