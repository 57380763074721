.page {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.page .Header .ant-menu {
  background-color: transparent;
  border-bottom: none;
  color: white;
}

.page .ant-menu a {
  color: white !important;
}

.page__content {
  background-color: #f5f5f5;
}

.page .page__content {
  flex-grow: 1;
}

.footer-app-version {
  color: #000000A6;
}

#submissionLink {
  background-color: white;
  color: teal;
  padding: 8px 12px;
  border: 1px solid white;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
}

.contactUsButton {
  right: 48px;
  height: 50px;
  width: 50px;
}
