.carousel-container {
    position: relative;
    width: 100%;
    max-width: 1270px;
    height: auto;
    margin: 0 auto;
    margin-top: 30px;
    overflow: hidden;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .carousel-wrapper {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }
  
  .carousel-wrapper::-webkit-scrollbar {
    display: none;
  }
  
  .carousel-content {
    display: flex;
    gap: 15px;
  }
  
  .carousel-item {
    flex: 0 0 auto;
  }
  
  .carousel-image {
    width: 100%;
    display: block;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .carousel-image:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .carousel-arrow.left {
    left: 5px;
  }
  
  .carousel-arrow.right {
    right: 5px;
  }
  
  .carousel-arrow:hover {
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .ant-image-preview-wrap {
    background-color: rgba(0, 0, 0, 0.85);
  }
  
  .ant-image-preview-img {
    height: 100vh;
  }

.ant-image-preview-operations .ant-image-preview-operations-operation-rotateLeft,
.ant-image-preview-operations .ant-image-preview-operations-operation-rotateRight,
.ant-image-preview-operations .ant-image-preview-operations-operation-flipY,
.ant-image-preview-operations .ant-image-preview-operations-operation-flipX {
  display: none;
}
