.spinner-children {
  width: 100vw;
  height: 100vh;
}

.spinner {
  color: teal;
}

.ant-spin-dot-item {
  background-color: teal !important;
}