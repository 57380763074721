body {
  margin: 0;
}

.ant-card-body .ant-space {
  width: 300;
  height: 150;
  display: flex;
}

.ant-menu-overflow-item .ant-menu-title-content a {
  outline-color: transparent !important;
}
 
.ant-menu-item-selected a {
  color: #6eceb2 !important;
  border-color: transparent !important;
  outline-color: transparent !important;
}
 
.ant-menu-item-selected li {
  color: #6eceb2 !important;
  border-color: transparent !important;
  text-decoration: underline;
  text-underline-offset: 10px;
}
 
.ant-menu-item-selected {
  color: white !important;
  text-decoration: underline 2px;
  text-underline-offset: 8px;
}

.ant-modal-footer .ant-btn {
  border-radius: 5px;
  font-weight: bold;
}
