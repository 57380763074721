.logged-in-user {
    padding: 10px;
    font-weight: 400;
    max-width: 400px;
  }
  
  .logged-in-user-avatar {
    margin-left: 4px;
  }
  
  .logged-in-user-logout {
    margin-left: 30px;
    padding: 6px;
    border-style: solid;
    border-width: thin;
    border-color: lightgrey;
    border-radius: 6px;
  }
