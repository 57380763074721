.ant-upload {
  border-radius: 8px !important;
}

.selected-file {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  padding: 10px;
  border-radius: 8px;
}

.file-info {
  display: flex;
  align-items: baseline;
  margin-top: -20px;
}

.file-name {
  font-size: larger;
}

.remove-button {
  margin-left: auto;
  border: 1px solid black;
  border-radius: 5px;
}

.progress {
  margin-top: 16px;
}

.upload-button-container {
  display: flex;
  justify-content: center;
}

.ant-btn {
  margin-top: 16px;
}

  